// extracted by mini-css-extract-plugin
export const sectionSpacing = "card-module--section-spacing--3dVAA";
export const rowSpacing = "card-module--row-spacing--1TF2J";
export const rowSpacingSml = "card-module--row-spacing-sml--2nmWA";
export const card = "card-module--card--3VdcU";
export const cardIcon = "card-module--cardIcon--AdWHV";
export const cardDesc = "card-module--cardDesc--2ccA8";
export const cardOrange = "card-module--cardOrange--2rRij";
export const descOrange = "card-module--descOrange--g47dY";
export const cardRed = "card-module--cardRed--3pEAj";
export const descRed = "card-module--descRed--8CBfw";
export const cardGreen = "card-module--cardGreen--1igfe";
export const descGreen = "card-module--descGreen--3eJg5";
export const cardTeal = "card-module--cardTeal--WgtNk";
export const descTeal = "card-module--descTeal--22c2C";
export const cardEmpty = "card-module--cardEmpty--11it4";
export const descEmpty = "card-module--descEmpty--DCAhr";