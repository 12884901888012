// import dependencies
import React from "react";

// import styles and assets
import * as styles from "../styles/separator.module.scss";

export default function SeparatorBottomTeal() {
    return (
<div className={styles.separator}>
    <svg width="100%" height="100%" viewBox="0 0 1280 160" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1,0,0,0.949644,0,5.65071)">
            <path d="M1280,94.323L1226.67,81.747C1173.33,69.17 1066.67,44.018 960,56.594C853.333,69.17 746.667,119.476 640,138.341C533.333,157.205 426.667,144.629 320,119.476C213.333,94.323 106.667,56.594 53.333,37.729L-0,18.865L-0,0L1280,0L1280,94.323Z" style={{fill: "rgb(2,151,148)"}}/>
        </g>
        <g transform="matrix(1,0,0,1,0.000114689,-0.104)">
            <path d="M1280,151.021L1226.67,154.165C1173.33,157.31 1066.67,163.598 960,157.31C853.333,151.021 746.667,132.157 640,113.292C533.333,94.427 426.667,75.563 320,69.275C213.333,62.986 106.667,69.275 53.333,72.419L-0,75.563L-0,0.104L1280,0.104L1280,151.021Z" style={{fill: "rgb(17,185,177)"}}/>
        </g>
    </svg>
</div>
    );
}