// import dependencies
import React from "react";

// import styles and assets
import * as styles from "../styles/separator.module.scss";

export default function SeparatorTopYellow() {
    return (
<div className={ styles.separator }>
    <svg width="1280" height="180" viewBox="0 0 1280 180" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1,0,0,0.966667,0,2.03449e-06)">
            <path d="M0,160L53.333,130C106.667,100 213.333,40 320,40C426.667,40 533.333,100 640,130C746.667,160 853.333,160 960,133.333C1066.67,106.667 1173.33,53.333 1226.67,26.667L1280,0L1280,180L0,180L0,160Z" style={{fill: "rgb(247,175,0)"}}/>
        </g>
        <path d="M0,60L53.333,80C106.667,100 213.333,140 320,146.667C426.667,153.333 533.333,126.667 640,100C746.667,73.333 853.333,46.667 960,33.333C1066.67,20 1173.33,20 1226.67,20L1280,20L1280,180L0,180L0,60Z" style={{fill: "rgb(254,212,48)"}}/>
    </svg>
</div>
    );
}