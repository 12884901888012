// import dependencies
import React from "react";

// import styles and assets
import * as styles from "./styles/section-header.module.scss";

export default function SectionHeader({children, color}) {
    switch (color) {
        case "dark":
            return (
                <div className="row ">
                    <div className={`col-sm-12 col-md-10 ${styles.sectionHeader} ${styles.dark}`}>
                        {children}
                    </div>
                </div>
            );
        case "green":
            return (
                <div className="row ">
                    <div className={`col-sm-12 col-md-10 ${styles.sectionHeader} ${styles.green}`}>
                        {children}
                    </div>
                </div>
            );
        case "orange":
            return (
                <div className="row ">
                    <div className={`col-sm-12 col-md-10 ${styles.sectionHeader} ${styles.orange}`}>
                        {children}
                    </div>
                </div>
            );
        case "red":
            return (
                <div className="row ">
                    <div className={`col-sm-12 col-md-10 ${styles.sectionHeader} ${styles.red}`}>
                        {children}
                    </div>
                </div>
            );
        case "turquoise":
            return (
                <div className="row ">
                    <div className={`col-sm-12 col-md-10 ${styles.sectionHeader} ${styles.turquoise}`}>
                        {children}
                    </div>
                </div>
            );
        case "yellow":
            return (
                <div className="row ">
                    <div className={`col-sm-12 col-md-10 ${styles.sectionHeader} ${styles.yellow}`}>
                        {children}
                    </div>
                </div>
            );
        case "white":
            return (
                <div className="row ">
                    <div className={`col-sm-12 col-md-10 ${styles.sectionHeader} ${styles.white}`}>
                        {children}
                    </div>
                </div>
            );
        default:
            return (
                <div className="row ">
                    <div className={`col-sm-12 col-md-10 ${styles.sectionHeader} ${styles.def}`}>
                        {children}
                    </div>
                </div>
            );
    }
}
