// import dependencies
import React from "react";

// import styles and assets
import * as styles from "./styles/header.module.scss";

export default function Header({children, bkgImage, mobileBkgImage, type}) {

    switch (type) {
        case "logo":
            return (
                <div className={`container-fluid ${styles.header}`}>
                    <div className="row">
                        <div className="col-md-6 col-sm-10 offset-md-3 offset-sm-1 d-flex flex-column justify-content-center">
                            <div className={styles.normalLogo} style={{backgroundImage: `url(${bkgImage})`}}>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>    
            );
        default:
            return (
                <div className={`container-fluid ${styles.header}`}>
                    <div className="row">
                        <div className="col-10 offset-1">
                            <div className={styles.mobileBlob} style={{backgroundImage: `url(${mobileBkgImage})`}}>
                                {children}
                            </div>
                            <div className={styles.normalBlob} style={{backgroundImage: `url(${bkgImage})`}}>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>    
            );
    }
}