// extracted by mini-css-extract-plugin
export const sectionSpacing = "section-header-module--section-spacing--1hKW6";
export const rowSpacing = "section-header-module--row-spacing--7fTff";
export const rowSpacingSml = "section-header-module--row-spacing-sml--13OdE";
export const sectionHeader = "section-header-module--sectionHeader--nux29";
export const yellow = "section-header-module--yellow--kSc79";
export const orange = "section-header-module--orange--cJn4I";
export const turquoise = "section-header-module--turquoise--MGJNA";
export const red = "section-header-module--red--3ydGx";
export const green = "section-header-module--green--20ddL";
export const white = "section-header-module--white--2L7Zg";
export const dark = "section-header-module--dark--3VzKU";
export const def = "section-header-module--def--2VapK";