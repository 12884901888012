// import dependencies
import React from "react";
import { Link } from "gatsby";

// import styles and assets
import * as styles from "./styles/button.module.scss";

export default function Button(props) {
    switch (props.color) {
        case 'yellow':    
            return (
                <Link to={props.url} className={`${styles.btn} ${styles.btnYellow}`}>
                    <p>{props.buttonText}</p>
                </Link>
            );
        case 'orange':
            return (
                <Link to={props.url} className={`${styles.btn} ${styles.btnOrange}`}>
                    <p>{props.buttonText}</p>
                </Link>
            );
        case 'turquoise':
            return (
                <Link to={props.url} className={`${styles.btn} ${styles.btnTurquoise}`}>
                    <p>{props.buttonText}</p>
                </Link>
            );
        case 'red':
            return (
                <Link to={props.url} className={`${styles.btn} ${styles.btnRed}`}>
                    <p>{props.buttonText}</p>
                </Link>
            );
        case 'green':
            return (
                <Link to={props.url} className={`${styles.btn} ${styles.btnGreen}`}>
                    <p>{props.buttonText}</p>
                </Link>
            );
        default:
            return (
                <Link to={props.url} className={`${styles.btn} ${styles.btnGreen}`}>
                    <p>{props.buttonText}</p>
                </Link>
            );
    }
}