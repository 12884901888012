// import dependencies
import React from "react";

// import components
import Button from "../components/button";

// import styles and assets
import corfoImg from "../images/stickers-cuadrados-kit.jpg";
import mineducImg from "../images/stickers-cuadrados-caja-herramientas.jpg";
import * as styles from "./styles/kits.module.scss";

export default function Header({children, bkgImage, mobileBkgImage}) {
    return (
<div className={`row ${styles.kitsRow}`}>
    <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-start align-items-center">
        <img src={corfoImg} alt="Logo corfo"/>
        <p><strong>Kit Ingeniosamente CORFO:</strong> una caja de herramientas realizada con el apoyo de CORFO, para realizar ejercicios de electrónica y un taller de astronomía.</p>
        <Button url="/corfo" color="turquoise" buttonText="Kit Ingeniosamente" />
    </div>
    <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-start align-items-center">
        <img src={mineducImg} alt="Logo ministerio de educación"/>
        <p><strong>Caja de herramientas STEM:</strong> una caja realizada para el proyecto Aprende STEM desde Casa del Ministerio de Educación e Ingeniosas. Materiales para 12 actividades, que incluyen talleres de electrónica, autoconfianza, ciencia, entre otros.</p>
        <Button url="/mineduc" color="turquoise" buttonText="Kit de Herramientas STEM" />
    </div>
</div>
    );
}