// import dependencies
import React from "react";
import {graphql} from "gatsby";
import PortableText from "@sanity/block-content-to-react";
import Serializers from "../utils/serializers";

// import components
import Button from "../components/button";
import Card from "../components/cards/card-empty";
import CardEngi from "../components/cards/card-engi";
import CardMath from "../components/cards/card-math";
import CardSci from "../components/cards/card-sci";
import CardTech from "../components/cards/card-tech";
import Header from "../components/header";
import Kits from "../components/kits";
import Layout from "../components/layout";
import Section from "../components/section";
import SectionHeader from "../components/section-header";
import SeparatorBottomTeal from "../components/separators/separator-bottom-teal";
import SeparatorBottomYellow from "../components/separators/separator-bottom-yellow";
import SeparatorTopGrey from "../components/separators/separator-top-grey";
import SeparatorTopTeal from "../components/separators/separator-top-teal";
import SeparatorTopYellow from "../components/separators/separator-top-yellow";

// import styles and assets
import photoHead from "../images/apoderados-page/apo-blob.png";
import photoHeadMob from "../images/apoderados-page/apo-blob-mobile.png";
import * as styles from "./styles/apoderados.module.scss";

const ApoderadosPage = ({data}) => (
<Layout>
    <Section color="white">
        <Header bkgImage={photoHead} mobileBkgImage={photoHeadMob}>
            <h1>Orientación para apoderados</h1>
        </Header>
        <SeparatorTopTeal />
    </Section>
    <Section color="turquoise">
        <div className={`container ${styles.inspiracion}`}>
            {data.inspirations.edges.map((edge) => (
                <div key={edge.node.id} className={`row ${styles.inspRow}`}>
                    <div className="col-10 offset-1">
                        <div className="row">
                            <h1>Inspiración</h1>
                            <h4>{edge.node.title}</h4>
                            <div className="separator"></div>
                            <PortableText blocks={edge.node._rawBody} serializers={Serializers} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </Section>
    <SeparatorBottomTeal />
    <Section color="white">
        <div className={`container ${styles.workMarket}`}>
            <SectionHeader color="turquoise">
                <h1>Conoce sobre el mercado laboral en STEM</h1>
                <h4>Si eres apoderada o apoderado, te invitamos a descubrir las posibilidades que tu hija o niña a cargo podría tener en un futuro laboral en ciencia y tecnología. Descubre con experiencias reales las posibilidades en estos rubros.</h4>
            </SectionHeader>
            <div className={`row ${styles.workMarketBox}`}>
                {data.merlab.edges.map((edge) => {
                    switch (edge.node.tags[0]) {
                        case "mathematics":
                            return(
                                <div key={edge.node.id} className="col-sm-12 col-md-6">
                                    <CardMath desc={edge.node.title} postUrl={`/mercado-laboral/${edge.node.slug.current}`}/>
                                </div>
                            );
                        case "engineering":
                            return(
                                <div key={edge.node.id} className="col-sm-12 col-md-6">
                                    <CardEngi desc={edge.node.title} postUrl={`/mercado-laboral/${edge.node.slug.current}`}/>
                                </div>
                            );
                        case "technology":
                            return(
                                <div key={edge.node.id} className="col-sm-12 col-md-6">
                                    <CardTech desc={edge.node.title} postUrl={`/mercado-laboral/${edge.node.slug.current}`}/>
                                </div>
                            );
                        case "science":
                            return(
                                <div key={edge.node.id} className="col-sm-12 col-md-6">
                                    <CardSci desc={edge.node.title} postUrl={`/mercado-laboral/${edge.node.slug.current}`}/>
                                </div>
                            );
                        default:
                            return(
                                <div key={edge.node.id} className="col-sm-12 col-md-6">
                                    <CardMath desc={edge.node.title} postUrl={`/mercado-laboral/${edge.node.slug.current}`}/>
                                </div>
                            );
                    }
                })}
            </div>
            <div className="row">
                <div className="d-flex col-12 justify-content-center">
                    <Button url="/mercado-laboral" color="orange" buttonText="Conoce más"></Button>
                </div>
            </div>            
        </div>
    </Section>
    <SeparatorTopYellow />
    <Section color="yellow">
        <div className={`container section-spacing ${styles.resources}`}>
            <SectionHeader color="white">
                <h1>Recursos externos</h1>
                <h4>Accede a un mundo de herramientas STEM que pueden ser útiles para acompañar a tu hija o niña a cargo, en el camino STEM.</h4>
            </SectionHeader>
            <div className={`row ${styles.extResBox}`}>
                {data.extRes.edges.map((edge) => (              
                    <div key={edge.node.id} className="col-sm-12 col-md-6 col-lg-4">
                        <Card desc={edge.node.title} postUrl={`/recursos-externo/${edge.node.slug.current}`}/>
                    </div>
                ))}
            </div>
        </div>
    </Section>
    <SeparatorBottomYellow />
    <Section color="white">
        <div className={`container section-spacing ${styles.kits}`}>
            <SectionHeader color="turquoise">
                <h1>Cajas interactivas</h1>
                <h4>Kit de Herramientas físicas que te permitirán realizar actividades y experimentos en ciencia y tecnología. </h4>
            </SectionHeader>
            <Kits/>
        </div>
    </Section>
    <SeparatorTopGrey />
</Layout>
);
export default ApoderadosPage;

export const query = graphql`
query PaginaApoderadosQuery {
    inspirations: allSanityPost(
        limit: 1,
        filter: {
            category: {title: {eq: "Inspiración"}}, 
            tags: {elemMatch: {title: {eq: "Apoderados"}}}, 
            title: {eq: "En Ingeniosas te orientamos cómo ayudar a tu hija en las STEM"}},
        sort: {order: DESC, fields: publishedAt}) {
        edges {
            node {
                id
                title
                tags {
                    title
                }
                slug {
                    current
                }
                excerpt
                publishedAt
                _rawBody(resolveReferences: {maxDepth: 10})
            }
        }
    }
    merlab: allSanityMercadorLaboralPost(
    limit: 4, 
    sort: {order: DESC, fields: publishedAt}) {
        edges {
            node {
                id
                title
                tags
                slug {
                    current
                }
                excerpt
            }
        }
    }
    extRes: allSanityPost(
        limit: 6, 
        filter: {category: {title: {eq: "Recursos externos"}}, tags: {elemMatch: {title: {eq: "Apoderados"}}}}, sort: {order: DESC, fields: publishedAt}) {
        edges {
            node {
                id
                title
                slug {
                   current
                }
            }
        }
    }
}
`;