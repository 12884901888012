// import dependencies
import React from "react";
import { Link } from "gatsby";

// import styles and assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../utils/font-awesome';
import * as styles from "../styles/card.module.scss";

export default function CardGreen(props) {
    return (
        <div className={styles.card}>
            <div className={`${styles.cardIcon} ${styles.cardOrange}`}>
                <p><FontAwesomeIcon icon={["fas", "flask"]} /></p>
            </div>
            <div className={`${styles.cardDesc} ${styles.descOrange}`}>
                <h4>{props.desc}</h4>
                <Link to={props.postUrl}><FontAwesomeIcon icon={["fas", "arrow-right"]} /></Link>
            </div>
        </div>
    );
}