// import dependencies
import React from "react";

// import styles and assets
import * as styles from "../styles/separator.module.scss";

export default function SeparatorBottomYellow() {
    return (
<div className={ styles.separator }>
    <svg width="1280" height="160" viewBox="0 0 1280 160" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1280,103.631L1226.67,109.539C1173.33,115.447 1066.67,127.262 960,127.262C853.333,127.262 746.667,115.447 640,109.539C533.333,103.631 426.667,103.631 320,103.631L-0,103.631L-0,32.738L640,32.738C746.667,32.738 853.333,32.738 960,32.738L1280,32.738L1280,103.631Z" style={{fill: "rgb(247,175,0)"}}/>
        <g transform="matrix(1,0,0,0.95,0,6.65588)">
            <path d="M1280,17.778L1226.67,20.741C1173.33,23.704 1047.67,23.704 941,50.37C834.333,77.037 651.167,125.685 544.5,140.5C437.833,155.315 350.167,143.463 243.5,140.5C136.833,137.537 106.667,148.148 53.333,154.074L-0,160L-0,-0L1280,0L1280,17.778Z" style={{fill: "rgb(247,175,0)"}}/>
        </g>
        <path d="M1280,160L1226.67,139.259C1173.33,118.519 1066.67,77.037 960,80C853.333,82.963 746.667,130.37 640,124.444C533.333,118.519 426.667,59.259 320,47.407C213.333,35.556 106.667,71.111 53.333,88.889L-0,106.667L-0,-0L1280,0L1280,160Z" style={{fill: "rgb(254,212,48)"}}/>
    </svg>
</div>
    );
}